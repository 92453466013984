import React, { useState } from 'react';
import classNames from 'classnames';
import {
  TERMS_CONDITONS, PRIVACY_POLICY,
  COOKIE_POLICY, FAQ, SUPPORT_PROBLEM, OUR_VALUES,
} from 'constants/navigation';
import { features } from 'gatsby-env-variables';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserHeadset,
  faBalanceScale, faUserSecret,
  faCookieBite, faAngleUp, faAngleDown,
  faFileAlt, faQuestionCircle, faBug, faSeedling,
} from '@fortawesome/pro-light-svg-icons';

import { isAuthenticated } from 'services/authentication-service';

import {
  ListItem, ListItemText, Collapse, List, ListItemIcon,
} from '@material-ui/core';
import BaseNavigation from 'components/navigation/side-bar/navigation-factory/base-nav';

import useStyles from '../styles';

const SupportNavigation = () => {
  const styles = useStyles();
  const [openSubMenu, setSubMenuOpen] = useState(false);
  const [openDocsMenu, setDocsMenuOpen] = useState(false);

  const { faq = false, showValues = false } = features;
  const authenticated = isAuthenticated();

  return (
    <>
      <ListItem
        button
        className={classNames(openSubMenu ? styles.opened : '')}
        onClick={() => setSubMenuOpen(!openSubMenu)}
      >
      <ListItemIcon className={styles.iconWrapper}>
        <FontAwesomeIcon icon={faUserHeadset} size="2x" className={styles.icon}/>
      </ListItemIcon>
        <ListItemText variant="inset" primary="Support" />
        { openSubMenu
          ? <FontAwesomeIcon icon={faAngleUp} size="2x"/>
          : <FontAwesomeIcon icon={faAngleDown} size="2x"/>
        }
      </ListItem>
      <Collapse
        component="li"
        className={styles.listLinks}
        in={openSubMenu}
        timeout="auto"
        unmountOnExit
      >
        <List dense>

        {authenticated && <BaseNavigation
            classes={styles}
            description="Tech Support"
            iconChild={<FontAwesomeIcon icon={faBug} size="2x" className={styles.icon}/>}
            navigation={SUPPORT_PROBLEM}
            islistLinkItem
            />}

        {faq && <BaseNavigation
          classes={styles}
          description="FAQ"
          iconChild={<FontAwesomeIcon icon={faQuestionCircle} size="2x" className={styles.icon}/>}
          navigation={FAQ}
          islistLinkItem
          />}

        <ListItem
          button
          className={classNames(openDocsMenu ? styles.opened : '')}
          onClick={() => setDocsMenuOpen(!openDocsMenu)}
        >
          <ListItemIcon className={styles.childListWrapper}>
            <FontAwesomeIcon icon={faFileAlt} size="2x" className={styles.icon}/>
          </ListItemIcon>
            <ListItemText variant="inset" primary="Documents" />
            { openDocsMenu
              ? <FontAwesomeIcon icon={faAngleUp} size="2x"/>
              : <FontAwesomeIcon icon={faAngleDown} size="2x"/>
            }
          </ListItem>
            <Collapse
              component="li"
              className={styles.listLinks}
              in={openDocsMenu}
              timeout="auto"
              unmountOnExit
            >
            <List dense>
              <BaseNavigation
                classes={styles}
                description="Terms"
                iconChild={<FontAwesomeIcon icon={faBalanceScale} size="2x" className={styles.icon}/>}
                navigation={TERMS_CONDITONS}
                islistLinkItem
                />
              <BaseNavigation
                classes={styles}
                description="Privacy"
                iconChild={<FontAwesomeIcon icon={faUserSecret} size="2x" className={styles.icon}/>}
                navigation={PRIVACY_POLICY}
                islistLinkItem
                />
              <BaseNavigation
                classes={styles}
                description="Cookies"
                iconChild={<FontAwesomeIcon icon={faCookieBite} size="2x" className={styles.icon}/>}
                navigation={COOKIE_POLICY}
                islistLinkItem
                />
          </List>
        </Collapse>
        {showValues && authenticated && <BaseNavigation
          classes={styles}
          description="Values"
          iconChild={<FontAwesomeIcon icon={faSeedling} size="2x" className={styles.icon}/>}
          navigation={OUR_VALUES}
          islistLinkItem
          />}
        </List>
      </Collapse>
    </>
  );
};

export default SupportNavigation;
