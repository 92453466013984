import theme, { isDark } from 'theme';
import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

export default makeStyles({
  drawerInner: {
    height: '100%',
    position: 'fixed',
    width: drawerWidth,

  },
  drawerPaperClose: {
    width: 72,
    position: 'fixed',
    overflow: 'hidden',
    marginTop: theme.spacing(0),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    '&:hover': {
      width: drawerWidth,
      boxShadow: theme.shadows[6],
    },
    '& $menuContainer': {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1.5),
      width: drawerWidth,
    },
    '& $drawerInner': {
      width: 'auto',
    },
  },
  paddMenuContainer: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    background: isDark() ? '#333333' : '#F5F5F5', // theme.palette.background.default,
    paddingRight: theme.spacing(1),
    overflow: 'hidden',
    height: '100vh',
    position: 'relative',
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(10.5),
    },
  },
  menuContainer: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    background: isDark() ? '#333333' : '#F5F5F5', // theme.palette.background.default,
    paddingRight: theme.spacing(1),
    overflow: 'hidden',
    height: '100vh',
    position: 'relative',
    display: 'block',
  },

  logoContainer: {
    paddingTop: `${theme.spacing(0.75)}px !important`,
  },
});
