import theme from 'theme';

export const makeButtonStyles = (customTheme) => ({
  cardbutton: {
    paddingTop: `${customTheme.spacing(1)}px !important`,
    paddingBottom: `${customTheme.spacing(1)}px !important`,
    position: 'absolute !important',
    right: '0 !important',
    bottom: '0 !important',
    backgroundColor: `${customTheme.buttonBackgroundColor} !important`,
    color: `${theme.palette.common.white} !important`,
    fontSize: `${customTheme.typography.fontSize * 1.5}px !important`,
    '&:hover': {
      backgroundColor: `${customTheme.buttonBackgroundColorHover} !important`,
    },
  },
  backbutton: {
    fontSize: customTheme.typography.fontSize * 1.5,
  },
  greenbutton: {
    paddingTop: `${customTheme.spacing(1)}px !important`,
    paddingBottom: `${customTheme.spacing(1)}px !important`,
    backgroundColor: `${customTheme.secondButtonBackgroundColor} !important`,
    color: `${theme.palette.common.white} !important`,
    fontWeight: `${customTheme.typography.fontWeightBold} !important`,
    fontSize: `${customTheme.typography.fontSize * 1.15}px !important`,
    '&:hover': {
      backgroundColor: `${customTheme.secondBackgroundColorHover} !important`,
    },
  },
  button: {
    paddingTop: `${customTheme.spacing(1)}px !important`,
    paddingBottom: `${customTheme.spacing(1)}px !important`,
    backgroundColor: `${customTheme.buttonBackgroundColor} !important`,
    color: `${theme.palette.common.white} !important`,
    fontSize: `${customTheme.typography.fontSize * 1.5}px !important`,
    '&:hover': {
      backgroundColor: `${customTheme.buttonBackgroundColorHover} !important`,
    },
  },
  noColorButton: {
    paddingTop: `${customTheme.spacing(1)}px !important`,
    paddingBottom: `${customTheme.spacing(1)}px !important`,
    fontWeight: `${customTheme.typography.fontWeightBold} !important`,
    fontSize: `${customTheme.typography.fontSize * 1.15}px !important`,
  },
  landingbutton: {
    marginTop: `${customTheme.spacing(2)}px !important`,
    marginBottom: `${customTheme.spacing(4)}px !important`,
    padding: `${customTheme.spacing(2)}px !important`,
    fontSize: `${customTheme.typography.fontSize * 1.5}px !important`,
  },
  noColorButtonDisabled: {
    paddingTop: `${customTheme.spacing(1)}px !important`,
    paddingBottom: `${customTheme.spacing(1)}px !important`,
    cursor: 'default',
    fontWeight: `${customTheme.typography.fontWeightBold} !important`,
    fontSize: `${customTheme.typography.fontSize * 1.15}px !important`,
  },
  appBarButton: {
    textDecoration: 'none !important',
    color: `${theme.palette.common.white} !important`,
  },
  dropzonebutton: {
    marginTop: `${customTheme.spacing(2)}px !important`,
    marginBottom: `${customTheme.spacing(4)}px !important`,
    padding: `${customTheme.spacing(2)}px !important`,
    fontSize: `${customTheme.typography.fontSize * 1.5}px !important`,
  },
  locationbutton: {
    padding: `${customTheme.spacing(2)}px !important`,
    fontSize: `${customTheme.typography.fontSize}px !important`,
  },
  formbutton: {
    marginTop: `${customTheme.spacing(2)}px !important`,
    marginBottom: `${customTheme.spacing(4)}px !important`,
    padding: `${customTheme.spacing(2)}px !important`,
    fontSize: `${customTheme.typography.fontSize * 1.5}px !important`,
    backgroundColor: `${customTheme.buttonBackgroundColor} !important`,
    color: `${theme.palette.common.white} !important`,
    '&:hover': {
      backgroundColor: `${customTheme.buttonBackgroundColorHover} !important`,
    },
  },
  greenformbutton: {
    marginTop: `${customTheme.spacing(2)}px !important`,
    marginBottom: `${customTheme.spacing(4)}px !important`,
    padding: `${customTheme.spacing(2)}px !important`,
    fontSize: `${customTheme.typography.fontSize * 1.5}px !important`,
    backgroundColor: `${customTheme.secondButtonBackgroundColor} !important`,
    color: `${theme.palette.common.white} !important`,
    '&:hover': {
      backgroundColor: `${customTheme.secondBackgroundColorHover} !important`,
    },
  },
  formbuttonNoColor: {
    marginTop: `${customTheme.spacing(2)}px !important`,
    marginBottom: `${customTheme.spacing(4)}px !important`,
    padding: `${customTheme.spacing(2)}px !important`,
    fontSize: `${customTheme.typography.fontSize * 1.5}px !important`,
    fontWeight: `${customTheme.typography.fontWeightBold} !important`,
  },
  billingButton: {
    marginTop: `${customTheme.spacing(1)}px !important`,
    padding: `${customTheme.spacing(2)}px !important`,
    fontSize: `${customTheme.typography.fontSize * 1.5}px !important`,
    backgroundColor: `${customTheme.buttonBackgroundColor} !important`,
    color: `${theme.palette.common.white} !important`,
    '&:hover': {
      backgroundColor: `${customTheme.buttonBackgroundColorHover} !important`,
    },
  },
  paddedButton: {
    paddingTop: `${customTheme.spacing(1)}px !important`,
    paddingBottom: `${customTheme.spacing(1)}px !important`,
    backgroundColor: `${customTheme.buttonBackgroundColor} !important`,
    fontWeight: `${customTheme.typography.fontWeightBold} !important`,
    fontSize: `${customTheme.typography.fontSize * 1.15}px !important`,
    color: `${theme.palette.common.white} !important`,
    '&:hover': {
      backgroundColor: `${customTheme.buttonBackgroundColorHover} !important`,
    },
  },
  largeIcon: {
    textAlign: 'center',
    marginTop: customTheme.spacing(2),
    color: customTheme.palette.secondary.light,
  },
  backIcon: {
    textAlign: 'center',
    margin: '0 !important',
    padding: '0 !important',
    fontSize: customTheme.typography.fontSize * 2,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: customTheme.spacing(2),
    marginBottom: customTheme.spacing(1),
  },
  buttonIcon: {
    fontSize: `${customTheme.typography.fontSize * 2}px !important`,
  },
  submitIcon: {
    // fontSize: customTheme.typography.fontSize * 10,
    marginLeft: customTheme.spacing(1),
    [customTheme.breakpoints.down('xs')]: {
      marginLeft: customTheme.spacing(1),
    },
  },
  buttonIconNoSize: {
    marginLeft: customTheme.spacing(1),
    paddingBottom: customTheme.spacing(0.5),
    [customTheme.breakpoints.down('xs')]: {
      marginLeft: customTheme.spacing(1),
    },
  },
});

const buttonStyles = makeButtonStyles(theme);

export default buttonStyles;
