import React from 'react';
import { OUR_VALUES } from 'constants/navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSeedling } from '@fortawesome/pro-light-svg-icons';
import BaseNavigation from '../base-nav';

import useStyles from '../styles';

const ContactNavigation = () => {
  const styles = useStyles();

  return (
      <BaseNavigation
        classes={styles}
        description="Values"
        iconChild={<FontAwesomeIcon icon={faSeedling} size="2x" className={styles.icon}/>}
        navigation={OUR_VALUES}
        islistLinkItem
        />
  );
};

export default ContactNavigation;
