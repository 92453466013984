import theme from 'theme';
import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

export default makeStyles({
  drawerPaper: {
    width: drawerWidth,
    overflowX: 'hidden',
    height: '100%',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      paddingTop: `${theme.spacing(8.5)}px !important`,
    },
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    overflow: 'auto',
    height: '100%',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    [theme.breakpoints.up('md')]: {
      paddingTop: `${theme.spacing(0)}px !important`,
    },

    [theme.breakpoints.up('sm')]: {
      width: 70,
      '&:hover': {
        width: drawerWidth,
        boxShadow: theme.shadows[6],
      },
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    '@media (min-width:600px)': {
      minHeight: theme.spacing(3.5),
    },
    minHeight: theme.spacing(2.5),
  },
});
