import React from 'react';
import {
  object, node, string, bool,
} from 'prop-types';
import { navigate } from 'gatsby';

import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

const BaseNavigation = ({
  classes,
  iconChild,
  navigation,
  description,
  islistLinkItem = false,
}) => (
      <ListItem
        button
        onClick={() => navigate(navigation)}
        className={islistLinkItem ? classes.listLinkItem : ''}
        >
        <ListItemIcon className={classes.iconWrapper}>
          {iconChild}
        </ListItemIcon>
        <ListItemText primary={description} />
      </ListItem>
);

BaseNavigation.propTypes = {
  classes: object.isRequired,
  description: string.isRequired,
  iconChild: node.isRequired,
  navigation: string.isRequired,
  islistLinkItem: bool,
};

export default BaseNavigation;
