import React from 'react';
import { CONTACT_US } from 'constants/navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/pro-light-svg-icons';
import BaseNavigation from '../base-nav';

import useStyles from '../styles';

const ContactNavigation = () => {
  const styles = useStyles();

  return (
      <BaseNavigation
        classes={styles}
        description="Contact"
        iconChild={<FontAwesomeIcon icon={faPaperPlane} size="2x" className={styles.icon}/>}
        navigation={CONTACT_US}
        islistLinkItem
        />
  );
};

export default ContactNavigation;
