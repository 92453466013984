import theme from 'theme';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  iconWrapper: {
    marginRight: 0,
    marginLeft: 0,
  },
  icon: {
    marginRight: 0,
    color: theme.palette.secondary.light,
  },
  listLinks: {
    listStyle: 'none',
  },
  listLinkItem: {
    margin: theme.spacing(0.25),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  childListWrapper: {
    margin: theme.spacing(0.5),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
});
