import theme from 'theme';
import { makeStyles } from '@material-ui/core/styles';
import buttonStyles from 'apputil/button-styles';

export default makeStyles({
  ...buttonStyles,
  menuButton: {
    marginLeft: 0,
  },
  logoText: {
    paddingTop: theme.spacing(0.7),
    paddingLeft: theme.spacing(1),
    textDecoration: 'none',
    color: theme.palette.common.white,
    [theme.breakpoints.down(theme.breakpoints.values.iphoneFive)]: {
      marginLeft: theme.spacing(-1),
      paddingLeft: theme.spacing(0),
    },
  },

  logo: {
    width: 60,
    height: 60,
    paddingTop: theme.spacing(0),
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0),
  },
  stickyAppBar: {
    left: '3.5%',
    width: '96.5%',
    position: 'fixed',
  },
  navButtonIcon: {
    marginTop: theme.spacing(-0.5),
  },
  landingBarButton: {
    position: 'absolute !important',
    right: theme.spacing(2),
    marginLeft: `${theme.spacing(1)}px !important`,
    paddingRight: `${theme.spacing(2)}px !important`,
  },
  firstButton: {
    marginRight: theme.spacing(1),
  },
  navIcon: {
    marginRight: theme.spacing(1.5),
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(0),
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
    },
  },
});

export const useAppBarClasses = makeStyles({
  root: {
    zIndex: `${theme.zIndex.drawer + 1} !important`,
    backgroundColor: `${theme.appBarBackgroundColor} !important`,
  },
});
